/* styles.css */
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background: #f0f2f5;
  }
  
  .login-box {
    margin-top: 100px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .submit-btn {
    margin-top: 20px;
    background-color: #3f51b5;
  }
  
  .submit-btn:hover {
    background-color: #303f9f;
  }
  
  .home-box {
    margin-top: 100px;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .logout-btn {
    margin-top: 20px;
    background-color: #f44336;
  }
  
  .logout-btn:hover {
    background-color: #d32f2f;
  }
  